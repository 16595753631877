import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";

//* config
import {
  AppointmentSchedulePersistConfig,
  GetSchedulingRulePersistConfig,
  adminProfilePersistConfig,
  commonPersistConfig,
  onBoardingPersistConfig,
  patientFormProfilePersistConfig,
  patientProfilePersistConfig,
  providerProfilePersistConfig,
  userMessagePersistConfig,
} from "./PersistConfig";

// *slicer
import { userProfileSection } from "./Slicers/userProfileSlicer";
import { meditationSection } from "./Slicers/meditationSlicer";
import { careHistorySection } from "./Slicers/careHistorySlicer";
import { careTeamSection } from "./Slicers/careTeamsSlicer";
import { commonSlicer } from "./Slicers/commonSlicer";
import { proceduresSection } from "./Slicers/proceduresSlicer";

//* profile slice
import PatientProfileSlice from "./Slicers/ProfileInformation/patientProfileSlice";
import AdminProfileSlice from "./Slicers/ProfileInformation/adminProfileSlice";
import ProviderProfileSlice from "./Slicers/ProfileInformation/providerProfileSlice";

//* message slice
import UserMessageSlice from "./Slicers/Messages/userMessageSlicer";
import GetAppointmentsSlice from "./Slicers/ScheduleAppointment/GetAppointmentsSlice";

// user services
import UserServicesSlice from "./Slicers/VirtualService/UserServices";
import SchedullingAppointmentSlice from "./Slicers/SchedulingAppointments/SchedulingAppointmentSlicer";
import AppointmentDateViewSlice from "./Slicers/Appointment/AppointmentDateViewSlicer";
import AppointmentSlice from "./Slicers/Appointment/AppointmentSlicer";
import AppointmentByStatusSlice from "./Slicers/Appointment/AppointmentByStatusslicer";
import patientFormProfileSlice from "./Slicers/PatientFormsData/PatientFormsDataSlicer";
import LeaveRequestSlicer from "./Slicers/LeaveRequestData/allLeaveRequestSlicer";
import LeaveRequestDataByProviderSlicer from "./Slicers/LeaveRequestData/getLeaveRequestByProvider";
import LeaveRequestByDateSlicer from "./Slicers/LeaveRequestData/getLeaveRequestByDate";
import { OnBoardingSlicer } from "./Slicers/OnBoarding/OnBoardingSlicer";
import GetSchedulingRule from "./Slicers/ProviderSchedulingRuleData/GetSchedulingRuleSlicer";
import GetProviderListByAvailability from "./Slicers/ProviderSchedulingRuleData/getProviderListByAvailabilitySlicer";
import AdminScheduleAppointmentSlice from "./Slicers/AdminScheduleAppointment/AdminScheduleAppointmentSlicer";
import EventDataByProviderIdSlice from "./Slicers/UserShift/getEventDataByProviderIdSlicer";
import AllAdminProfileSlice from "./Slicers/ProfileInformation/allAdminProfileSlice";
import UpcomingAppointmentSlice from "./Slicers/Appointment/UpcomingAppointmetsslicer";
import ProviderByServiceSlice from "./Slicers/ProviderByService/ProviderByServiceSlicer";
import AllProviderAppointmentSlice from "./Slicers/Appointment/AllProviderAppointmentSlicer";
import AppointmentByDateStatusSlice from "./Slicers/Appointment/AppointmentByDateStatusSlicer";
import waitingRoomPatientDataSlice from "./Slicers/ProviderChatSidebarDetail/waitingRoomPatientDataSlicer";
import ProviderContactHistorySlice from "./Slicers/GetContactHistory/providerContactHistorySlice";
import NotificationPreferencesSlice from "./Slicers/NotificationPreferences/NotificationPreferencesSlicer";
import AllPatientProfileSlice from "./Slicers/ProfileInformation/allPatientProfileSlice";
import ProviderDetailSlice from "./Slicers/ProfileInformation/ProviderDetailSlice";

// schedulling

/* Combine All Reducers And Return Store */
export const store = configureStore({
  reducer: {
    app: persistReducer(
      commonPersistConfig,
      commonSlicer.reducer
    ),
    userProfile: userProfileSection.reducer,
    careTeam: careTeamSection.reducer,
    careHistory: careHistorySection.reducer,
    meditationSection: meditationSection.reducer,
    procedures: proceduresSection.reducer,

    // onboarding slicer
    onBoarding: persistReducer(
      onBoardingPersistConfig,
      OnBoardingSlicer.reducer
    ),

    // * profile reducers
    patientProfile: persistReducer(
      patientProfilePersistConfig,
      PatientProfileSlice.reducer
    ),

    AllPatientProfileData: AllPatientProfileSlice.reducer,
    
    adminProfile: persistReducer(
      adminProfilePersistConfig,
      AdminProfileSlice.reducer
    ),
    providerProfile: persistReducer(
      providerProfilePersistConfig,
      ProviderProfileSlice.reducer
    ),
    AllAdminUserData:AllAdminProfileSlice.reducer,
    //* Message reducer
    userMessage: persistReducer(
      userMessagePersistConfig,
      UserMessageSlice.reducer
    ),
    //* Appointment reducer
    userAppointment: persistReducer(
      AppointmentSchedulePersistConfig,
      GetAppointmentsSlice.reducer
    ),
    appointments: combineReducers({
      AppointmentData: AppointmentSlice.reducer,
      AllAppointmentData: AllProviderAppointmentSlice.reducer,
      AppointmentDateViewData: AppointmentDateViewSlice.reducer,
      AppointmentByStatusSlice: AppointmentByStatusSlice.reducer,
      UpcomingAppointmentSlice: UpcomingAppointmentSlice.reducer,
      AppointmentByDateStatusSlice: AppointmentByDateStatusSlice.reducer
    }),

    //* Provider by Service

    ProviderByService : ProviderByServiceSlice.reducer,
    NotificationPreferences : NotificationPreferencesSlice.reducer,

    PatientFormData: persistReducer(
      patientFormProfilePersistConfig,
      patientFormProfileSlice.reducer
    ),
    schedulingRule: persistReducer(
      GetSchedulingRulePersistConfig,
      GetSchedulingRule.reducer
    ),
    ProviderListByAvailability: GetProviderListByAvailability.reducer,
    userServices: UserServicesSlice.reducer,
    userSchedulling: SchedullingAppointmentSlice.reducer,
    adminSchedule: AdminScheduleAppointmentSlice.reducer,
    EventByProviderId: EventDataByProviderIdSlice.reducer || [],
    waitingRoomPatientData:waitingRoomPatientDataSlice.reducer,
    LeaveRequest: combineReducers({
      LeaveRequestData: LeaveRequestSlicer.reducer,
      RequestDataByDate: LeaveRequestByDateSlicer.reducer,
      LeaveRequestDataProvider: LeaveRequestDataByProviderSlicer.reducer,
    }),
    ProviderContactHistory :ProviderContactHistorySlice.reducer,
    ProviderDetails :ProviderDetailSlice.reducer,
  },
  middleware:
    process.env.NODE_ENV === "development" ? [thunk,logger] : [thunk],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
