import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUrl } from "../../../Network/ApiUrl";
import { RootState } from "../..";
import { post } from "../../../Network/ApiService";

interface storeIndividualProviderArgs {
    data: any;
    authToken: string;
}

export const storeIndividualProvider = createAsyncThunk<any, storeIndividualProviderArgs, { state: RootState }>(
    "onboarding/storeIndividualProvider",
    async ({ authToken, data }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.onboarding.create_individual_onboarding}`;

            const response = await post(ApiEndPoint, authToken, data);
            if (response) {
                return response;
            } else {
                throw new Error('Failed to fetch date view data of provider based on service_id');
            }
        } catch (error) {
            throw error;
        }
    }
)