import React, { useEffect, useState } from 'react'
import ImageHandler from '../../../Components/Common/ImageHandler'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Store';
import images from '../../../Assets/Images';
import { capitalizeFirstLetter, getInitials } from '../../../Components/utils';
import { reset, setIsUserSignIn, setPlan } from '../../../Store/Slicers/commonSlicer';
import routeHandlers from '../../../Components/routeHandler';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getLocalStorage, logoutHandler } from '../../../Components/Patient/Utils/AuthHandlers';
import { useWebSocket } from '../../../SocketConnection/WebSocketContext';
import { fetchPatientProfileData } from '../../../Store/Thunk/getProfileData/patientProfileThunks';
import CompleteProfileModal from '../../../Components/Common/Modals/CompleteProfileModal/CompleteProfileModal';
import { Amplify } from 'aws-amplify';
import { PatientAwsConfig } from '../../../awsConfig';

const CommonWaiting = ({ componentName }: any) => {

    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const UserId = getLocalStorage("UserId");
    const authToken = getLocalStorage('userToken')

    const userDataPatient = getLocalStorage("userData");

    const { themeColor, plan, slider, fullScreen, startVideoCall } = useSelector((state: RootState) => state.app);
    const PatientUserData = useSelector((state: RootState) => state.patientProfile.PatientUserData)?.[0]
    const patientProfileModal = useSelector((state: RootState) => state.patientProfile.PatientProfileModal);

    const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)

    const { disconnectWebSocket } = useWebSocket();


    const ComponentToRender = routeHandlers[componentName]["component"];
    const prevLink = routeHandlers[componentName]["prevLink"];

    Amplify.configure({
        Auth: {
            region: PatientAwsConfig.REGION,
            userPoolId: PatientAwsConfig.USER_POOL_ID,
            userPoolWebClientId: PatientAwsConfig.USER_POOL_CLIENT_ID,
        },
    });


    const handleSet = (value: string) => {
        if (value === "enterprise") {
            navigate('/home')
        }
        dispatch(setPlan(value));
    }

    const handleLogout = () => {
        disconnectWebSocket()
        logoutHandler(plan)
        dispatch(setIsUserSignIn(false))
        dispatch(reset())
    }

    const handleClose = () => {
        setIsCompleteModalOpen(false)
    }
    const fetchData = async () => {
        const response: any = await dispatch(fetchPatientProfileData({ userId: userDataPatient.sub ? userDataPatient.sub : UserId, authToken }))

        if (patientProfileModal && location.pathname !== "/patient-profile") {
            if (location.pathname === "/patient/meeting-screen") {
                setIsCompleteModalOpen(true)
            }
        }
    }
    const currunt_url = new URL(window.location.href)
    const hostname = currunt_url.hostname
    useEffect(() => {
        fetchData()

        if (hostname === 'clinic.centiblickconnect.com') {
            dispatch(setPlan('clinic'))
        }
    }, [])

    useEffect(() => {
        if (patientProfileModal && location.pathname !== "/patient-profile") {
            if (location.pathname === "/patient/meeting-screen") {
                setTimeout(() => {
                    setIsCompleteModalOpen(true)
                }, 100);
            }
        }
    }, [isCompleteModalOpen, location.pathname, patientProfileModal])


    return (
        <div className="main-page-wrapper" style={{ height: fullScreen ? "100vh" : "" }}>
            <div className="header" style={{ display: fullScreen ? "none" : "block" }}>
                <nav className="navbar">
                    <div className="container-fluid">
                        <button
                            className="navbar-toggler d-md-none"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar"
                            aria-controls="offcanvasNavbar"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="custom-navbar">
                            <div
                                className="nav-left d-md-flex cursor-pointer"
                                style={{ display: "none" }}
                                onClick={() => navigate('/')}
                            >
                                <h1>Secure Telehealth</h1>
                                <img src={images.authPower} alt="power" className="power-img" />
                            </div>
                            <div className="nav-right">
                                <div className="dropdown dropdown-patient">
                                    <button className={`btn`} type="button" id="dropdownMenuButton1" data-bs-toggle={``} aria-expanded="false">
                                        {capitalizeFirstLetter(plan)}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        {
                                            plan != "Individual" &&
                                            <li><a className="dropdown-item" href="#" onClick={() => handleSet('Individual')}>Individual</a></li>
                                        }
                                        {plan != "enterprise" &&
                                            <li><a className="dropdown-item" href="#" onClick={() => handleSet('enterprise')}>Enterprise</a></li>
                                        }
                                        {plan != "clinic" &&
                                            <li><a className="dropdown-item" href="#" onClick={() => handleSet('clinic')}>Clinic</a></li>
                                        }
                                    </ul>
                                </div>
                                <div className="language-select">
                                    <ImageHandler
                                        theme={themeColor}
                                        imageName="languageImage"
                                        className=""
                                    />
                                    <select
                                        defaultValue={"en"}
                                        className="form-select"
                                        aria-label="Default select example"
                                    //   onChange={(e) => langaugeHandler(e)}
                                    >
                                        <option value="en">Eng</option>
                                        <option value="fr">Fre</option>
                                    </select>
                                </div>
                                <button
                                    className="btn logout-btn"
                                    onClick={handleLogout}
                                >
                                    Logout
                                </button>
                            </div>
                        </div>

                    </div>
                </nav>
            </div>
            <div className='d-flex'>
                <div className={`sidebar-wrapper sidebar-toggle ${slider ? 'open-sidebar' : 'closed-sidebar'}`} style={{ height: fullScreen ? "100vh" : "" }}>
                    <div
                        className="sidebar-user patient-sidebar-user d-md-flex"
                        style={{ backgroundColor: "#ffffff", height: "100%" }}
                    >
                        {
                            PatientUserData && PatientUserData.profile_image_path ?
                                <img src={process.env.REACT_APP_AWS_S3_BUCKET_PATH + "/" + PatientUserData?.profile_image_path} className="shadow-none blue_adminHomePamel cursor-pointer" width={170} alt='profile-img' onClick={() => navigate('/patient/meeting-screen')} />
                                :
                                <div className="cursor-pointer para-img-div-profile d-flex" onClick={() => navigate('/patient/meeting-screen')}>
                                    <div className="para-circle-avatar-profile">
                                        <p className="para-image-profile">{getInitials(PatientUserData?.first_name ? PatientUserData.first_name + " " + PatientUserData.last_name : "Patient 001")}</p>
                                    </div>
                                </div>
                        }
                        <h2>
                            {PatientUserData?.first_name ? PatientUserData.first_name + " " + PatientUserData.last_name : "Patient 001"}
                        </h2>
                        <button
                            className="btn theme-btn account-btn"
                            onClick={() => navigate("/patient-profile")}
                        >
                            my Profile
                        </button>
                    </div>
                </div>
                <div className={`w-100 ${!startVideoCall ? 'p-4' : 'p-0'}`}>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active cursor-pointer" aria-current="page" onClick={() => { prevLink && navigate(prevLink) }}>
                                {prevLink &&
                                    <Link to={prevLink}>
                                        <ImageHandler
                                            theme={themeColor}
                                            imageName="Down"
                                            className=""
                                        />
                                    </Link>
                                }
                                {routeHandlers[componentName]["componentTitle"]}
                            </li>
                        </ol>
                    </nav>
                    {/* {deviceWidth > 767 ? <ComponentToRender /> : null} */}
                    <ComponentToRender />

                </div>
            </div>
            {
                isCompleteModalOpen && <CompleteProfileModal isProfileModalOpen={isCompleteModalOpen} handleClose={handleClose} navigateUrl="/patient-profile" />
            }
        </div>
    )
}

export default CommonWaiting