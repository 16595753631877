import React, { useEffect, useRef } from "react";
import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables);

interface chartType {
  data: any;
  options: any;
  chartType: any;
  height?: any;
  plugins?: any;
  className?: string;
}

const Chart = ({ data, options, chartType, height, plugins, className }: chartType) => {
  const chartContainer = useRef<any>(null);
  const chartInstance = useRef<any>(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      chartInstance.current = new ChartJS(chartContainer.current, {
        type: chartType,
        data: data,
        options: options,
        plugins: plugins && plugins
      });
    }
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartContainer, data, options]);

  return (
    <div>
      <canvas ref={chartContainer} width="400" height={height && height} className={className && className}/>
    </div>
  )
};

export default Chart;
