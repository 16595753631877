import React from 'react'

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../Store';

//css
import UpcomingAppointments from './UpcomingAppointments';

import '../../Header/CommonHeader.scss';
import DefaultImage from '../../../../../../Assets/Svgs/ImageUploadSquare.svg'
import images from '../../../../../../Assets/Images';
import { lightenColor } from '../../../../../../Components/utils';

interface SidebarComponentProps {
  ComponentTitle?: "Standard Platform" | "Customize"
}

const HomeDeviceListing = ({ ComponentTitle }: SidebarComponentProps) => {



  const { selectedType } = useSelector((state: RootState) => state.onBoarding);
  const { selectedPrimaryColor, selectedSecondaryColor } = useSelector((state: RootState) => state.onBoarding.selectedColors);

  // patient images
  const { firstImage, secondImage }: any = useSelector((state: RootState) => state.onBoarding.selectedImage);

  // provder images
  const { thirdImage: ProviderThirdImage, secondImage: ProviderSecondImage }: any = useSelector((state: RootState) => state.onBoarding.ProviderselectedImage);

  // admin images
  const { firstImage: AdminFirstImage, secondImage: AdminSecondImage, thirdImage: AdminThirdImage, forthImage: AdminForthImage }: any = useSelector((state: RootState) => state.onBoarding.AdminselectedImage);

  function CarouselImage({ ComponentTitle, itemType }: any) {
    switch (ComponentTitle) {
      case "Customize":
        // Handle Customize case based on itemType
        switch (itemType) {
          case "Patient":
            return [firstImage ? firstImage : DefaultImage, secondImage ? secondImage : DefaultImage];
          case "Provider":
            return [ProviderSecondImage ? ProviderSecondImage : DefaultImage, ProviderThirdImage ? ProviderThirdImage : DefaultImage];
          case "Admin":
            return [AdminFirstImage ? AdminFirstImage : DefaultImage, AdminSecondImage ? AdminSecondImage : DefaultImage, AdminThirdImage ? AdminThirdImage : DefaultImage, AdminForthImage ? AdminForthImage : DefaultImage];
          default:
            return [DefaultImage, DefaultImage, DefaultImage, DefaultImage];
        }

      case "Standard Platform":
        // Handle Standard Platform case based on itemType
        switch (itemType) {
          case "Patient":
            return [images.CHCareLogo, images.CHForms];
          case "Provider":
            return [images.providerTasksSquare, images.carouselPatientAppointments];
          case "Admin":
            return [images.carouselProviderProfile, images.carouselPatientProfile, images.carouselSchedule, images.carouselPatientAppointments];
          default:
            return [DefaultImage];
        }

      default:
        return [DefaultImage];
    }
  }

  let PatientItems = [
    {
      name: "my Virtual Care",
      image: CarouselImage({ ComponentTitle, itemType: "Patient" })
    },
    {
      name: "my Forms",
      image: CarouselImage({ ComponentTitle, itemType: "Patient" })
    },
  ];

  const providerItems = [
    {
      name: "my Appointments",
      image: CarouselImage({ ComponentTitle, itemType: "Provider" })

    },
    {
      name: "my Schedule",
      image: CarouselImage({ ComponentTitle, itemType: "Provider" })
    },
  ]
  const AdminItems = [
    {
      name: "Provider Profiles",
      image: CarouselImage({ ComponentTitle, itemType: "Admin" })
    },
    {
      name: "Patient Profiles",
      image: CarouselImage({ ComponentTitle, itemType: "Admin" })
    },
    {
      name: "Scheduling",
      image: CarouselImage({ ComponentTitle, itemType: "Admin" })
    },
    {
      name: "Patient Appointments",
      image: CarouselImage({ ComponentTitle, itemType: "Admin" })
    },
  ]
  const filteredAdminCarouselDetails = (() => {
    switch (selectedType) {
      case 'Patient':
        return PatientItems;
      case "Provider":
        return providerItems;
      case 'Admin':
        return AdminItems;
      default:
        return PatientItems
    }
  })();



  const Ring1 = `<svg width="131" height="113" viewBox="0 0 131 113" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style="mix-blend-mode:multiply" opacity="0.05">
    <path d="M75.7553 98.3561C109.963 98.3561 137.415 70.3443 137.415 36.1361C137.415 1.92798 109.963 -26.0838 75.7553 -26.0838C41.5477 -26.0838 14.0959 1.92798 14.0959 36.1361C14.0959 70.3443 41.5477 98.3561 75.7553 98.3561Z" stroke="${ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor}" stroke-width="28.027"/>
    </g>
    </svg>
    `

  const Ring2 = `<svg width="131" height="80" viewBox="0 0 131 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style="mix-blend-mode:multiply" opacity="0.05">
    <path d="M75.7553 138.661C109.963 138.661 137.415 110.649 137.415 76.4408C137.415 42.2327 109.963 14.2209 75.7553 14.2209C41.5477 14.2209 14.0959 42.2327 14.0959 76.4408C14.0959 110.649 41.5477 138.661 75.7553 138.661Z" stroke="${ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor}" stroke-width="28.027"/>
    </g>
    </svg>
    `

  const Ring3 = `<svg width="205" height="207" viewBox="0 0 205 207" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style="mix-blend-mode:multiply" opacity="0.05">
    <path d="M102.678 197.931C154.584 197.931 196.568 155.592 196.568 103.48C196.568 51.3684 154.584 9.0294 102.678 9.0294C50.7717 9.0294 8.78721 51.3684 8.78721 103.48C8.78721 155.592 50.7717 197.931 102.678 197.931Z" stroke="${ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor}" stroke-width="16.8162"/>
    </g>
    <g style="mix-blend-mode:multiply" opacity="0.05">
    <path d="M102.67 162.051C134.756 162.051 160.686 135.907 160.686 103.755C160.686 71.6039 134.756 45.4591 102.67 45.4591C70.5844 45.4591 44.6544 71.6039 44.6544 103.755C44.6544 135.907 70.5844 162.051 102.67 162.051Z" stroke="${ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor}" stroke-width="16.8162"/>
    </g>
    </svg>
    `


  return (
    <div className="care-history-mobile hometasklist mt-3 mx-3 col-md-8 screen-appointment">
      <div dangerouslySetInnerHTML={{ __html: Ring1 }} className='ring-1' />
      <div dangerouslySetInnerHTML={{ __html: Ring2 }} className='ring-2' />
      <div dangerouslySetInnerHTML={{ __html: Ring3 }} className='ring-3' />

      <div className={`row hybridPatient`}>
        <h1 className='clinic-meeting-header'>
          Your call with Dr. De Marco will start soon
        </h1>
        <div className='clinic-meeting d-flex' style={{borderColor: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor, background: ComponentTitle === "Standard Platform" ? "" : lightenColor(selectedPrimaryColor,0.8)}}>
          <div className='d-flex flex-column align-items-center clinic-details w-100' style={{borderColor: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor,}}>
            <img src={images.ClinicProvider} alt="" width={59} height={59} />
            <div>
              <h1 >
                Dr. De Marco
              </h1>
              <p>
                MD, PhD
              </p>
            </div>
            <div className='provider-staus'>
              Offline
            </div>
            <p>Waiting for your provider to come online</p>
          </div>
          <div className='clinic-chat w-100'style={{borderColor: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor,}}>
            <h1 className='p-1' style={{background: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor,}}>
              Send Dr. De Marco a message
            </h1>
            <div className='clinic-chat-div'>

            </div>
            <div className='clinic-send-chat'>
              <input
                type="text"
                className='w-75'
                placeholder='Type your message...'
                style={{borderColor: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor,}}
              />
              <button
                className="btn w-25 account-btn theme-btn shadow-none"
                style={{
                  color: "#fff", background: ComponentTitle === "Standard Platform" ? "" : `radial-gradient(
                  107.08% 85.59% at 86.3% 87.5%,
                  rgba(0, 0, 0, 0.23) 0%,
                  rgba(0, 0, 0, 0) 86.18%
                ),
                radial-gradient(
                  83.94% 83.94% at 26.39% 20.83%,
                  rgba(255, 255, 255, 0.41) 0%,
                  rgba(255, 255, 255, 0) 69.79%,
                  rgba(255, 255, 255, 0) 100%
                ),
                ${selectedPrimaryColor}`
                }}
              >
                send
                {/* {selectedType === "Provider" ? "Update Profile" : "Update my Account"} */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeDeviceListing;