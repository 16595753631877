import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

interface meetingMessages {
  text: string;
  fromUser: boolean;
  timestamp: string;
}
export interface AppState {
  themeColor: string;
  language: string;
  detailsPageTitle: string;
  plan: string;
  providerPlan: string;
  adminPlan: string;
  isUserSignIn: boolean;
  seletedId: string;
  socketProviderData: any;
  providerHome: boolean;
  ccpInitialize: boolean;
  chatConnected: boolean;
  meetingMessages: meetingMessages[]
  providerChatMessages: meetingMessages[],
  callType: string,
  callStatus: string,
  room_id: string,
  meeting_id: string,
  slider: boolean,
  fullScreen: boolean,
  startVideoCall: boolean,
}

const currunt_url = new URL(window.location.href)
const hostname = currunt_url.hostname
let plans;
if (hostname === 'provider.centiblickconnect.com') {
  plans = 'Individual'
} else if (hostname === 'clinic.centiblickconnect.com') {
  plans = 'clinic'
} else {
  plans = 'clinic'
}

const initialState: AppState = {
  themeColor: "blue",
  language: "en",
  detailsPageTitle: "",
  plan: plans,
  providerPlan: plans,
  adminPlan: plans,
  isUserSignIn: false,
  seletedId: "",
  socketProviderData: [],
  providerHome: false,
  ccpInitialize: false,
  chatConnected: false,
  meetingMessages: [],
  providerChatMessages: [],
  callType: "",
  callStatus: "",
  room_id: '',
  meeting_id: '',
  slider: true,
  fullScreen: false,
  startVideoCall: false,
};


export const commonSlicer = createSlice({
  name: "app",
  initialState,
  reducers: {
    reset: () => initialState,
    getTheme: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.themeColor = action.payload;
    },
    setLanguage: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.language = action.payload;
    },
    setDetailsPageTitle: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.detailsPageTitle = action.payload;
    },
    setPlan: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.plan = action.payload;
    },
    setProviderplan: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.providerPlan = action.payload;
    },
    setAdminplan: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.adminPlan = action.payload;
    },
    setIsUserSignIn: (
      state: Draft<typeof initialState>,
      action: PayloadAction<boolean>
    ) => {
      state.isUserSignIn = action.payload;
    },
    setSeletedId: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.seletedId = action.payload;
    },
    setSocketProviderData: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.socketProviderData = action.payload;
    },
    setIsProviderHome: (
      state: Draft<typeof initialState>,
      action: PayloadAction<boolean>
    ) => {
      state.providerHome = action.payload;
    },
    setCcpInitialize: (
      state: Draft<typeof initialState>,
      action: PayloadAction<boolean>
    ) => {
      state.ccpInitialize = action.payload;
    },
    setChatConnected: (
      state: Draft<typeof initialState>,
      action: PayloadAction<boolean>
    ) => {
      state.chatConnected = action.payload;
    },
    setmeetingMessages: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.meetingMessages.push(action.payload);
    },
    setProviderChatMessages: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.providerChatMessages.push(action.payload);
    },
    setCallType: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.callType = action.payload;
    },
    setCallStatus: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.callStatus = action.payload;
    },
    setRoomId: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.room_id = action.payload;
    },
    setMeetingId: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.meeting_id = action.payload;
    },
    setSlider: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.slider = action.payload;
    },
    setFullScreen: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.fullScreen = action.payload;
    },
    setStartVideoCall: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.startVideoCall = action.payload;
    },
  },
});

export const { getTheme, setLanguage, setDetailsPageTitle, setPlan, setProviderplan, setAdminplan, setIsUserSignIn, reset, setSeletedId, setSocketProviderData, setIsProviderHome, setCcpInitialize, setChatConnected, setmeetingMessages, setProviderChatMessages, setCallType, setCallStatus, setRoomId, setSlider, setFullScreen, setStartVideoCall, setMeetingId } =
  commonSlicer.actions;
