import React from "react";

// ui components
import Sidebar from "../Sidebar/sidebar";

// utils components
import ImageHandler from "../../../Common/ImageHandler";
import HeaderTitleHandler from "../../Utils/HeaderTitleHandler";

// assets
import "./header.css";
import images from "../../../../Assets/Images";
import { useHeader } from "./useHeader";
import { logoutHandler as HeaderLogout } from "../../../utils";
import { logoutHandler } from "../../Utils/AuthHandlers";

const Header = ({ userData }: any) => {
  const {
    navigate,
    themeColor,
    plan,
    langaugeHandler,
    logout,
    handleSet,
    capitalizeFirstLetter,
    handleClinic
  } = useHeader()

  return (
    <div className="header">
      <nav className="navbar">
        <div className="container-fluid">
          <button
            className="navbar-toggler d-md-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="custom-navbar">
            <div
              className="nav-left d-md-flex cursor-pointer"
              style={{ display: "none" }}
              onClick={() => HeaderLogout()}
            >
              <HeaderTitleHandler themeColor={themeColor} />
              <img src={images.authPower} alt="power" className="power-img" />
            </div>
            <div className="nav-right">
              <div className="dropdown dropdown-patient">
                <button className="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="" aria-expanded="false">
                  {capitalizeFirstLetter(plan)}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  {
                    plan != "Individual" &&
                    <li><a className="dropdown-item" href="#" onClick={() => handleClinic('Individual')}>Individual</a></li>
                  }
                  {plan != "enterprise" &&
                    <li><a className="dropdown-item" href="#" onClick={() => handleSet('enterprise')}>Enterprise</a></li>
                  }
                  {plan != "clinic" &&
                    <li><a className="dropdown-item" href="#" onClick={() => handleClinic('clinic')}>Clinic</a></li>
                  }
                  {/* {plan != "upgrades" &&
                    <li><a className="dropdown-item" href="#" onClick={() => handleSet('upgrades')}>Upgrades</a></li>
                  } */}
                </ul>
              </div>
              {/* <select className="form-select select-menu-header" aria-label="Default select example" value={plan}
                onChange={handleSelectChange}>
                <option value="essentials">Essential</option>
                <option value="advanced">Advanced</option>
                <option value="upgrade">Upgrades</option>
              </select> */}
              <div className="language-select">
                <ImageHandler
                  theme={themeColor}
                  imageName="languageImage"
                  className=""
                />
                <select
                  defaultValue={"en"}
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => langaugeHandler(e)}
                >
                  <option value="en">Eng</option>
                  <option value="fr">Fre</option>
                </select>
              </div>
              <button
                className="btn logout-btn"
                onClick={logout}
              >
                Logout
              </button>
            </div>
          </div>
          <div
            className="offcanvas offcanvas-start d-lg-none"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <Sidebar userData={userData} />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
