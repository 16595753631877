import React, { useEffect } from 'react'
import images from '../../../Assets/Images';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setLocalStorage } from '../../../Components/Patient/Utils/AuthHandlers';
const ClientSignup = () => {

    const navigate = useNavigate()

    const currunt_url = new URL(window.location.href)
    const hostname = currunt_url.hostname
    const validHostnames = ["clinic.centiblickconnect.com", "provider.centiblickconnect.com","localhost"];

    const pathname = currunt_url.pathname;

    const pathSegments = pathname.split('/');

    const code = pathSegments[pathSegments.length - 1];

    useEffect(() => {
        if (validHostnames.includes(hostname) && (pathname.startsWith('/provider/') || pathname.startsWith('/patient/')) && code !== 'login') {
            navigate('/patient/waiting-screen')
            setLocalStorage('room_id', code)
        } else if (!code || code === 'login') {
            navigate('/')
            toast.error('Meeting Id is incorrect please contact with your provider')
        } else if ((hostname === "centiblickconnect.com" ) && (pathname.startsWith('/provider/') || pathname.startsWith('/patient/')) && code !== 'login') {
            navigate('/patient-demo-login')
        }
    }, [])


    return (
        <>
            <div className="row signup mt-lg-5">
                <div className="col-12 p-0 mx-auto boxmodle-width">
                    <div className='d-flex justify-content-center row'>
                        <div className="col-lg-6 p-0">
                            <div className='margin-set'>
                                <h2 className='text mt-5'>Hybrid Health</h2>
                                <div className="contextdataBox">
                                    <div className="contentdata mt-5">
                                        <span className='contentText'>HH</span>
                                    </div>
                                </div>
                                <div className="linka mb-4 mt-4 text-center">Client logo</div>
                            </div>
                        </div>
                        <div className="card col-lg-5 mb-3 mt-3 p-0">
                            <div className='row'>
                                <span className='mb-3 mt-3 signuptext text-center'>Sign in with your email address and password</span>
                            </div>
                            <div className='border box mb-4 mt-4 mx-3'>
                                <div className="form-group mb-3 mt-3">
                                    <input type="text" className="form-control inputflied" placeholder='Enter Email Address' />
                                </div>
                                <div className="form-group mb-3">
                                    <input type="text" className="form-control inputflied" placeholder='Enter Password' />
                                </div>
                                <p className='float-end linka'>Forgot Your Password ?</p>
                                <div className="form-check mb-4 mt-5">
                                    <input type="checkbox" className="form-check-input mr-10 mt-0" style={{ padding: "12px" }} />
                                    <label className="form-check-label mt-1" > I have read the <Link to="" className='policy'>Privacy Policy</Link></label>
                                </div>

                                <button className="btn text-center theme-btn w-100">Sign In</button>
                                <div className='mt-4 mb-3 font-weight'>Sign in with your Social account</div>
                                <div className='d-flex justify-content-between'>
                                    <img src={images.applefillClient} alt="applefillClient" className="apple" />
                                    <img src={images.amazonfillClient} alt="amazonfillClient" className="amzon" />
                                    <img src={images.googleClient} alt="googleClient" className="google" />
                                    <img src={images.facebookClient} alt="facebookClient" className="facebook" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ClientSignup;