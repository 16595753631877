/* eslint-disable */
import React, { useEffect } from "react";
import i18n from "./Language/i18n";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "./Store/Slicers/commonSlicer";

// component
import AppRoutes from "./AppRoutes";

// aws imports
import { Amplify } from "aws-amplify";

// config
import { AwsConfig } from "./awsConfig";

// assets
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./Assets/Css/style.css";
import "./Assets/Css/common.css";
import { RootState } from "./Store";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WebSocketProvider } from "./SocketConnection/WebSocketContext";

// Amplify.configure({
//   Auth: {
//     region: AwsConfig.REGION,
//     userPoolId: AwsConfig.USER_POOL_ID,
//     userPoolWebClientId: AwsConfig.USER_POOL_CLIENT_ID,
//   },
// });

function App() {
  const { themeColor, language } = useSelector((state: RootState) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    if (language !== undefined) {
      dispatch(setLanguage(language));
      i18n.changeLanguage(language);
    } else {
      dispatch(setLanguage("en"));
      i18n.changeLanguage("en");
    }
  }, [language]);

  return (
    <>
      <WebSocketProvider>
        <div data-theme={themeColor}>
          <ToastContainer position="top-right" autoClose={5000} style={{ zIndex: "10000" }} />
          <AppRoutes />
        </div>
      </WebSocketProvider>
    </>
  );
}

export default App;
