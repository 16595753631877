import React, { useEffect, useState } from 'react'

//components
import CommonHeader from '../../Platform/Header/CommonHeader';
import Sidebar from '../../Platform/Sidebar/Sidebar';
import SelectTypeHeader from '../../Platform/Header/SelectTypeHeader';
import LaptopComponent from './LaptopComponent';
import LaptopBlackSidebar from '../../Platform/Sidebar/LaptopBlackSidebar/LaptopBlackSidebar';

// redux
import { RootState } from '../../../../../Store';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFinishedLogin, setType } from '../../../../../Store/Slicers/OnBoarding/OnBoardingSlicer';

//css
import '../../Platform/Header/CommonHeader.scss'
import '../../../ClinicOnBoarding.scss';
import { getLocalStorage } from '../../../../../Components/Patient/Utils/AuthHandlers';
import { storeIndividualProvider } from '../../../../../Store/Thunk/Onboarding/storeIndividualProviderThunk';
import { toast } from 'react-toastify';

interface Step4Props {
    handleBackButtonClick: () => void;
    handleCheckoutButtonClick: () => void;
    planName?: string;
}

const Step4 = ({ handleBackButtonClick, handleCheckoutButtonClick, planName }: Step4Props) => {
    const { isfinishedSaving, selectedType } = useSelector((state: RootState) => state.onBoarding)
    const { selectedPrimaryColor, selectedSecondaryColor } = useSelector((state: RootState) => state.onBoarding.selectedColors)
    const { textColor, headerText } = useSelector((state: RootState) => state.onBoarding.serviceValues)
    const dispatch = useDispatch()

    const userData = getLocalStorage('userData')
    const data = getLocalStorage('data')
    const authToken = getLocalStorage('userToken')
    const admin = getLocalStorage('admin')
    const provider = getLocalStorage('provider')

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isStartedVisible, setIsStartedVisible] = useState(true)

    const [title, setTitle] = useState<"Standard Platform" | "Customize" | null>(null)

    const handleModalOpen = () => {
        setTitle('Customize')
        setIsModalOpen(true);
    }
    const handleModalClose = () => {
        setIsModalOpen(false);
    }

    const handleSaveData = async () => {
        toast.loading('Please wait...')
        try {
            const payload = {
                account_id: userData.sub,
                email: data.email,
                title: 'Mr.',
                first_name: data.firstName,
                last_name: data.lastName,
                preferred_name: data.firstName + ' ' + data.lastName,
                provider_acknowledgement: data.provider_acknowledgement,
                terms_agreement_acknowledgement: data.terms_agreement_acknowledgement,
                platform_type: title,
                service_name: headerText,
                text_color_code: textColor,
                primary_color: selectedPrimaryColor,
                secondary_color: selectedSecondaryColor,
                admin: admin ? admin : [],
                provider: provider ? provider : [],
            }
            const response: any = await dispatch(storeIndividualProvider({ data: payload, authToken }))
            if (response.payload.status === 200) {
                
                toast.dismiss();
                toast.success('Data saved successfully')
                handleCheckoutButtonClick()
            }
        } catch (error) {
            toast.dismiss();
            toast.error('Something went wrong')
            console.log("🚀 ~ handleSaveData ~ error:", error)
        }
    }

    // useEffect(() => {
    //     const handleClickOutside = (event: any) => {
    //         if (isModalOpen && event.target.classList.contains('laptop-model')) {
    //             handleModalClose();
    //         }
    //     };

    //     document.addEventListener('click', handleClickOutside);

    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // }, [isModalOpen]);

    useEffect(() => {
        if (isfinishedSaving && !isModalOpen) {
            setIsStartedVisible(false)
        }
    }, [isfinishedSaving])

    const handleTypeChange = (type: string) => {
        dispatch(setType(type))
    }

    const standardPlatform = () => {
        setTitle("Standard Platform")
        dispatch(setIsFinishedLogin(true))
    }

    const handleButtonClick = () => {
        setTitle("Customize")
        setIsModalOpen(true)
    }

    return (
        <div className='w-100'>
            <div className={`onboarding-main-flow ${isModalOpen ? "laptop-modal-parent" : "d-flex "}`} style={{ marginTop: "15px" }}>
                <div className='d-flex flex-column step-4' style={isfinishedSaving ? {} : { gap: "60px" }}>
                    <div className='d-flex flex-column gap-3'>
                        {/* <p className='p-top'>
                            STEP 4
                        </p> */}
                        {isModalOpen &&
                            <>
                                <div className='row laptop-modal-body'>
                                    <div className="col-xl-9 col-md-8 h-100 p-0">
                                        <div className="inner-screen outer-height position-relative" id="bg-ring">
                                            <div className='laptop-notch'></div>
                                            <div className='image'>

                                            </div>
                                            <SelectTypeHeader />
                                            <CommonHeader />
                                            <Sidebar ComponentTitle='Customize' />
                                        </div>

                                    </div>
                                    <div className="col-xl-3 col-md-4 p-0 outer-black-sidebar">
                                        <LaptopBlackSidebar handleModalClose={handleModalClose} />
                                    </div>
                                </div>
                            </>}
                        <div className={`text-center ${isModalOpen && 'd-none'}`}>

                            <h1 className='onboarding-heading'>{isfinishedSaving ? "Let's look at your platform" : "Use Our Standard Platform or Customize Your Own"}</h1>
                            {
                                !isfinishedSaving &&
                                <p className='onboarding-detail' style={{ color: "#516cd3" }}>A branded telehealth platform demonstrates innovation, enhances the patient experience, and strengthens trust in your healthcare organization.</p>
                            }
                        </div>
                    </div>

                    <div className={`d-flex gap-3 flex-column justify-content-center ${isModalOpen && 'd-none'}`}>
                        <div className='position-relative'>
                            {
                                isfinishedSaving &&
                                <div className='d-flex justify-content-center gap-4 select-type'>
                                    <p className={`type ${selectedType === "Patient" && 'active-type'}`} onClick={() => handleTypeChange("Patient")}>Patient</p>
                                    <p className={`type ${selectedType === "Provider" && 'active-type'}`} onClick={() => handleTypeChange("Provider")}>Provider</p>
                                </div>
                            }
                            <div className='d-flex justify-content-center flex-column position-relative'>
                                {
                                    !isfinishedSaving && !isModalOpen &&
                                    <>

                                        <div className='d-flex gap-4 step4-two-laptop-screen'>
                                            <div className='d-flex flex-column justify-content-center'>
                                                <LaptopComponent isStartedVisible={isStartedVisible} title="Standard Platform" />
                                                <div className='d-flex justify-content-center'>
                                                    <button className='customize-theme-btn' onClick={standardPlatform}>Standard Platform</button>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column justify-content-center'>
                                                <LaptopComponent isStartedVisible={isStartedVisible} title="Customize" />
                                                <div className='d-flex justify-content-center'>
                                                    <button className='customize-theme-btn' onClick={handleModalOpen}>Customize</button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='d-flex position-absolute' style={{bottom:-2}}>
                                            <button className='onboarding-back main-delete-theme-btn' onClick={handleBackButtonClick}>Back</button>
                                        </div> */}
                                    </>
                                }
                                {
                                    isfinishedSaving &&
                                    (
                                        title === "Standard Platform" ?
                                            <div className='d-flex flex-column justify-content-center'>
                                                <LaptopComponent isStartedVisible={isStartedVisible} title="Standard Platform" />
                                            </div> : <div className='d-flex flex-column justify-content-center'>
                                                <LaptopComponent isStartedVisible={isStartedVisible} title="Customize" />
                                            </div>
                                    )
                                }
                            </div>

                            {
                                isfinishedSaving &&
                                <div className='dot-button-content w-100'>
                                    <div className='three-dots mt-4 d-flex gap-2 justify-content-center '>
                                        <div className={`dots ${selectedType == "Patient" && 'filled'}`}></div>
                                        <div className={`dots ${selectedType == "Provider" && 'filled'}`}></div>
                                    </div>
                                    <br />
                                    <div className='mt-2 d-flex justify-content-center p-2 gap-4'>
                                        <button className='btn pro-light-btn' style={{ background: "#fff" }} onClick={handleButtonClick}>Try Customizing</button>
                                        <button className='onboarding-checkout' onClick={handleSaveData} style={{ borderRadius: "109px" }}>Confirm and Continue</button>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Step4