import React, { useEffect, useState } from 'react'
import './LaptopBlackSidebar.scss';
import { useDispatch, useSelector } from 'react-redux';
import { resetOnBoarding, setAdminFirstImage, setAdminForthImage, setAdminSecondImage, setAdminThirdImage, setFirstImage, setIsFinishedLogin, setProviderFirstImage, setProviderSecondImage, setProviderThirdImage, setSecondImage, setSelecteSecondaryColor, setSelectedPrimaryColor, setTextColor, setType, setheaderTextvalue } from '../../../../../../Store/Slicers/OnBoarding/OnBoardingSlicer';

// components
import FirstContent from './StepContent/FirstContent';
import SecondContent from './StepContent/SecondContent';
import ThirdContent from './StepContent/ThirdContent';
import ForthContent from './StepContent/ForthContnet';
import FifthContent from './StepContent/FifthContent';
import WarningModel from './Helpers/WarningModel';
import { convertImageToBase64 } from '../../../../../../Components/utils';
import { RootState } from '../../../../../../Store';

interface LaptopBlackSidebarProps {
    handleModalClose: () => void;
}
const LaptopBlackSidebar = ({ handleModalClose }: LaptopBlackSidebarProps) => {
    const dispatch = useDispatch();
    const [index, setIndex] = useState<number>(1);
    const [showModal, setShowModal] = useState<boolean>(false)
    const SelectedType = useSelector((state: RootState) => state.onBoarding.selectedType);


    function Headertext() {
        switch (index) {
            case 1:
                return "Telehealth Service Name";
            case 2:
                return "Platform Colors";
        }
    }

    const handleNextClick = () => {
        setIndex(index + 1);
        if (index === 1) {
        } else if (index === 2) {
            dispatch(setIsFinishedLogin(true))
            dispatch(setType("Patient"))
            handleModalClose()
            setIndex(1)
        } 
        // else if (index === 3) {
        //     dispatch(setType("Provider"))
        // } else if (index === 4) {
        //     dispatch(setType("Admin"))
        // } else if (index === 5) {
        //     console.log('insdiee')
        // }
    }

    const handlePrevClick = () => {
        setIndex(index - 1)
        if (index - 1 === 4) {
            dispatch(setType("Provider"))
        } else if (index - 1 === 5) {
            dispatch(setType("Admin"))
        } else {
            dispatch(setType("Patient"))
        }
        if (index <= 1) {
            setIndex(1)
        }
    }

    useEffect(() => {
        if (SelectedType === "Patient") {
            setIndex(1)
        } else if (SelectedType === "Provider") {
            setIndex(4)
        } else if (SelectedType === "Admin") {
            setIndex(5)
        } else {
            setIndex(1)
            dispatch(setType("Patient"))
        }
    }, [])

    useEffect(() => {
    }, [index,SelectedType])

    const handleChangePrimary = (value: string) => {
        dispatch(setSelectedPrimaryColor(value))
    }
    const handleChangeSecondary = (value: string) => {
        dispatch(setSelecteSecondaryColor(value))
    }

    const firstImageChange = async (file: any) => {
        let FirstImage: any = await convertImageToBase64(file)
        dispatch(setFirstImage(FirstImage))
    };

    const secondImageChange = async (file: any) => {
        let SecondImage: any = await convertImageToBase64(file)
        dispatch(setSecondImage(SecondImage))
    }

    const handleProviderFirstImageChange = async (file: any) => {
        let ProviderFirstImage: any = await convertImageToBase64(file)
        dispatch(setProviderFirstImage(ProviderFirstImage))
    }

    const handleProviderSecondImageChange = async (file: any) => {
        let ProviderSecondImage: any = await convertImageToBase64(file)
        dispatch(setProviderSecondImage(ProviderSecondImage))
    }

    const handleProviderThirdImageChange = async (file: any) => {
        let ProviderThirdImage: any = await convertImageToBase64(file)
        dispatch(setProviderThirdImage(ProviderThirdImage))
    }

    const handleAdminFirstImageChange = async (file: any) => {
        let AdminFirstImage: any = await convertImageToBase64(file)
        dispatch(setAdminFirstImage(AdminFirstImage))
    }
    const handleAdminSecondImageChange = async (file: any) => {
        let AdminSecondImage: any = await convertImageToBase64(file)
        dispatch(setAdminSecondImage(AdminSecondImage))
    }
    const handleAdminThirdImageChange = async (file: any) => {
        let AdminThirdImage: any = await convertImageToBase64(file)
        dispatch(setAdminThirdImage(AdminThirdImage))
    }
    const handleAdminForthImageChange = async (file: any) => {
        let AdminForthImage: any = await convertImageToBase64(file)
        dispatch(setAdminForthImage(AdminForthImage))
    }

    const renderContent = () => {
        switch (index) {
            case 1:
                return <FirstContent />;
            case 2:
                return <SecondContent onChangePrimaryColor={handleChangePrimary} onChangeSecondaryColor={handleChangeSecondary} />;
            // case 3:
            //     return <ThirdContent onFirstImageChange={firstImageChange} onSecondImageChange={secondImageChange} />;
            // case 4:
            //     return <ForthContent onFirstImageChange={handleProviderFirstImageChange} onSecondImageChange={handleProviderSecondImageChange} onThirdImageChange={handleProviderThirdImageChange} />;
            // case 5:
            //     return <FifthContent onFirstImageChange={handleAdminFirstImageChange} onSecondImageChange={handleAdminSecondImageChange} onThirdImageChange={handleAdminThirdImageChange} onForthImageChange={handleAdminForthImageChange} />;
            default:
                return <FirstContent />;
        }
    }
    const handleRevert = () => {
        setShowModal(true)
    }
    
    const closeModal = () => {
        setShowModal(false);
    }

    const handleConfirm = () => {
        dispatch(resetOnBoarding())
        dispatch(setType("Patient"))
        setIndex(1);
        setShowModal(false)
    }
    return (
        <div className='d-flex black-theme-sidebar'>
            <div
                className="black-theme-screen-wraper"
            >
                <div className="black-sidebar screen-sidebar">
                    <div className='d-flex flex-column justify-content-between whole-content'>
                        <section>
                            <div className='top-header text-center '> {Headertext()}</div>
                            {renderContent()}
                        </section>
                        <div className='row p-4'>
                            <button className='font-para simple-button' onClick={handleRevert}>Revert all Changes</button>
                            <div className='d-flex pt-3 button-container'>
                                <button className={`font-para col-md-6 simple-button ${index === 1 ? "opacity-50" : ""}`} disabled={index === 1} onClick={handlePrevClick}>Back</button>
                                <button className=' col-md-6 next-button' onClick={handleNextClick}>{index === 5 ? "Save" : 'Next'}</button>
                            </div>
                        </div>
                        {showModal && <WarningModel showModal={showModal} closeModal={closeModal} handleConfirm={handleConfirm} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LaptopBlackSidebar;
