import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../Network/ApiUrl';
import { post } from '../../../Network/ApiService';
import { RootState } from '../../index';
import { toast } from 'react-toastify';

// Create the Thunk action

interface storeCheckoutSessionDataArgs {
    authToken: string;
    payload: any;
}

// Define the return type for the Thunk action
interface storeCheckoutSessionDataResponse {
    status: number;
    message: string;
}
export const storeCheckoutSessionData = createAsyncThunk<storeCheckoutSessionDataResponse, storeCheckoutSessionDataArgs, { state: RootState }>(
    'onboarding/storeCheckoutSessionData',
    async ({authToken,payload}) => {
        try {
            const ApiEndPoint = `${ApiUrl.stripe.create_checkout_session}`
            const response = await post(ApiEndPoint, authToken, payload);
            if (response.status === 200) {
                toast.success(response?.data.message);
                return { status: 200, message: 'Data saved successfully' };
            } else {
                toast.error(response?.data.message);
                return { status: response.status, message: 'Error in saving Data' };
            }
        } catch (error) {
            toast.error('Internal Server Error');
            return { status: 500, message: 'Internal Server Error' };
        }
    }
);
