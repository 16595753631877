import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AppState {
    selectedType: string;
    serviceValues: {
        headerText: string;
        textColor: string;
    },
    selectedColors: {
        selectedPrimaryColor: string;
        selectedSecondaryColor: string;
        selectedIconColor : string
    },
    selectedImage: {
        firstImage: string | null;
        secondImage: string | null;
    },
    ProviderselectedImage: {
        firstImage: string | null;
        secondImage: string | null;
        thirdImage: string | null;
    },
    AdminselectedImage: {
        firstImage: string | null;
        secondImage: string | null;
        thirdImage: string | null;
        forthImage: string | null;
    }
    isfinishedSaving: boolean;
}

const initialState: AppState = {
    selectedType: "Patient",
    serviceValues: {
        headerText: "Service Name",
        textColor: '#000',
    },
    selectedColors: {
        selectedPrimaryColor: '#516cd3',
        selectedSecondaryColor: '#f1f3f6',
        selectedIconColor :"#000000"
    },
    selectedImage: {
        firstImage: null,
        secondImage: null
    },
    ProviderselectedImage: {
        firstImage: null,
        secondImage: null,
        thirdImage: null
    },
    AdminselectedImage: {
        firstImage: null,
        secondImage: null,
        thirdImage: null,
        forthImage: null,
    },
    isfinishedSaving: false,
};

export const OnBoardingSlicer = createSlice({
    name: "onBoarding",
    initialState,
    reducers: {
        resetOnBoarding: () => initialState,
        setType: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.selectedType = action.payload;
        },
        setheaderTextvalue: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.serviceValues.headerText = action.payload;
        },
        setTextColor: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.serviceValues.textColor = action.payload;
        },
        setSelectedPrimaryColor: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.selectedColors.selectedPrimaryColor = action.payload;
        },
        setSelecteSecondaryColor: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.selectedColors.selectedSecondaryColor = action.payload;
        },
        setFirstImage: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.selectedImage.firstImage = action.payload;
        },
        setSecondImage: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.selectedImage.secondImage = action.payload;
        },
        setProviderFirstImage: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.ProviderselectedImage.firstImage = action.payload;
        },
        setProviderSecondImage: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.ProviderselectedImage.secondImage = action.payload;
        },
        setProviderThirdImage: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.ProviderselectedImage.thirdImage = action.payload;
        },
        setAdminFirstImage: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.AdminselectedImage.firstImage = action.payload;
        },
        setAdminSecondImage: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.AdminselectedImage.secondImage = action.payload;
        },
        setAdminThirdImage: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.AdminselectedImage.thirdImage = action.payload;
        },
        setAdminForthImage: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.AdminselectedImage.forthImage = action.payload;
        },
        setIsFinishedLogin: (
            state: Draft<typeof initialState>,
            action: PayloadAction<boolean>
        ) => {
            state.isfinishedSaving = action.payload;
        },
        setSelectedColor: (
            state: Draft<typeof initialState>,
            action: PayloadAction<string>
        ) => {
            state.selectedColors.selectedIconColor = action.payload;
        }
    },
});

export const { setType, setheaderTextvalue, setSelectedPrimaryColor, setTextColor,
    setSelecteSecondaryColor, setFirstImage, setSecondImage, setProviderFirstImage, setProviderSecondImage, setProviderThirdImage, setAdminFirstImage, setAdminSecondImage,
    setAdminThirdImage,
    setAdminForthImage,
    resetOnBoarding, setIsFinishedLogin, setSelectedColor
} =
    OnBoardingSlicer.actions;
